import NotFoundImage from "../../public/svg/not-found.svg";
import { SEO } from "@/components";
import { ROUTES } from "@/config";
import { LayoutPublic } from "@/containers";
import { useIsAuthenticated } from "@/providers";
import { AnchorButton } from "@narrative-software/narrative-web-ui";
import Image from "next/image";
import Link from "next/link";

const NotFound: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <LayoutPublic>
      <SEO title="Page not found | Narrative" />
      <div className="text-center">
        <h1 className="text-h2 font-semibold leading-tight md:text-h1">
          Aw dang, that’s a 404
        </h1>
        <p className="mt-6 md:mt-10 md:text-lg">
          We can’t seem to find the page you’re looking for. Please forgive us,
          friendo.
        </p>
      </div>
      <div className="flex justify-center mt-7 md:mt-10">
        <div className="w-full max-w-60">
          <Link
            href={`/${
              isAuthenticated ? ROUTES.ACCOUNT.SLUG : ROUTES.LOGIN.SLUG
            }`}
            passHref
          >
            <AnchorButton colour="black">
              Back to {isAuthenticated ? "dashboard" : "login"}
            </AnchorButton>
          </Link>
        </div>
      </div>
      <div className="flex justify-center mt-15">
        <Image
          src={NotFoundImage}
          alt="Page not found"
          width="188"
          height="335"
          unoptimized
          priority
        />
      </div>
    </LayoutPublic>
  );
};

export default NotFound;
